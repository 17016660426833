import Vue from 'vue';

import './plugins/exception-handler';
import App from './App.vue'
import VueGtag from "vue-gtag";

import router from './router';
import store from './store';
import VueFinalModal from 'vue-final-modal';

Vue.use(VueFinalModal());

//GTMtracking
Vue.use(VueGtag, {
  config: { id: "AW-996491823" }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
